




import { Component, Vue, Emit } from 'vue-property-decorator';
import AccountForm from '../plugins/AccountForm';
@Component({
  mixins: [
    AccountForm,
  ],
})
export default class SignupFormParent extends Vue {
  private stepNavi: StepNaviData[] = [
    {
      title: '入力',
    },
    {
      title: '確認',
    },
    {
      title: '完了',
    },
  ];
}
